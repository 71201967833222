import React, { Fragment } from 'react'
import { Grommet, Box, Header, Footer, Text, RangeInput, Button, Heading, ResponsiveContext } from 'grommet';
import { format } from 'date-fns'

const theme = {
  globa: {
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px'
    },
  },
};

const APIURL = 'https://i4ha13mqqh.execute-api.us-east-1.amazonaws.com/dev/'

const miliSecondsInADay = 60 * 60 * 24 * 1000
const stubQuestions = [
  {
    id: '222a466c-88f8-43a3-8962-db20e9a951e7',
    prompt: 'How exciting is this question?',
    type: 'RangeInput', 
    bounds: [0, 10],
    initial_value: 5,
    grain: .1,
    time_fielded: 1612134331062,
    duration: 1 * miliSecondsInADay,
  },
  {
    id: 'bbe602ce-bc10-4a83-a41e-46c3d3d8f42d',
    prompt: 'How exciting is this other question?',
    type: 'RangeInput', 
    bounds: [0, 10],
    initial_value: 5,
    grain: .1,
    time_fielded: 1712134331062,
    duration: 5 * miliSecondsInADay,
  },
  {
    id: '07a92ac4-cbb8-49c6-a5d9-94744e28c5c0',
    prompt: 'How exciting is this last question?',
    type: 'RangeInput', 
    bounds: [0, 10],
    initial_value: 5,
    grain: .1,
    time_fielded: 1689134331062,
    duration: 5 * miliSecondsInADay,
  },
]

function App() {
  const [answered, setAnswered] = React.useState(false)
  const [selectedQuestion, setSelectedQuestion] = React.useState({})
  const size = React.useContext(ResponsiveContext)
  
  const submitAnswer = (value) => {
    console.log('Submitting: ', value);
    const meta = {
      appCodeName: window.navigator.appCodeName || '',
      appName: window.navigator.appName || '',
      appVersion: window.navigator.appVersion || '',
      userAgent: window.navigator.userAgent || '',
      language: window.navigator.language || '',
      doNotTrack: window.navigator.doNotTrack || ''
    }
    const payload = {
      question: selectedQuestion.id,
      date_opened: selectedQuestion.date_opened,
      value: value,
      meta
    }
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify(payload)
    }

    fetch(`${APIURL}/response`, options).then( res => {
      return setAnswered(true)
    }).catch( err => {
      console.log(err)
      return setAnswered(true)
    })
  }
  
  function getQuestion () {
    const questionId = window.location.pathname.substr(1)
    const selectedQuestion = stubQuestions.reduce( (acc, question) => {
      if (question.id === questionId) {
        acc = question
      } 
      return acc
    }, {})
    selectedQuestion.date_opened = Date.now()
    return setSelectedQuestion(selectedQuestion)
  }
  React.useEffect(() => {
      getQuestion()
    }
  )


  function SelectedQuestion (props) {
    const {answer} = {...props}
    const question = selectedQuestion
    console.log('What huh?: ', question)
    if (Object.keys(question).length < 2) {
      return <QuestionNotFound/>
    }
    if (question.type === 'RangeInput') {
      if (Date.now() > question.time_fielded + question.duration) {
        return <QuestionResults question={question} />
      }
      return <RangeQuestion question={question} answer={answer} />
    }
  }

  function fetchQuestion (questionId) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }
    fetch(`${APIURL}/question/${questionId}`).then( res => {
      const response = res.json()
      console.log(response)
    })
  }

  function QuestionResults (props) {
    const [question] = {...props}

  }
  function QuestionNotFound () {
    return (
      <Fragment>
        <Text>Question not found</Text>
      </Fragment>
    )
  }

  function RangeQuestion (props) {
    const {question, answer} = {...props}
    const {prompt, bounds, grain, initial_value} = {...question}
    const [value, setValue] = React.useState(initial_value)
    return (
      <Fragment>
        <Heading level={3} responsive={true} textAlign="center">{prompt}</Heading>
        <RangeInput step={grain} min={bounds[0]} max={bounds[1]} value={value} onChange={event => setValue(event.target.value)}></RangeInput>
        <Button margin="medium" primary label="Submit" onClick={() => answer(value)}/>
      </Fragment>
    )
  }

  const dateString = format(new Date('2014, 1, 11'), 'cccc')
  return (
    <Grommet theme={theme} full>
      <Box fill background="light-2">
        <Header background="brand" align="center" justify="around">
          <Heading responsive={true} level={1} textAlign="center">Poll Roulette</Heading>
        </Header>
        <Box flex overflow="auto" gap="medium" pad="medium" align="center" justify="between">
          {
            answered ? 
            <Box justify="around">
              <Heading level={2} responsive={true} textAlign="center">
                Check back after  {format(new Date(selectedQuestion.time_fielded + selectedQuestion.duration), 'cccc')}
              </Heading>
            </Box>
            :
            <Box align="center" justify="around" width={size} gap={size}>
              <SelectedQuestion answer={submitAnswer}/>
            </Box>
          } 
        </Box>
        <Footer align="center" as="footer" justify="around" background="brand" gap="medium" pad="small">
          <Text>Made with 🌮s in Houston</Text>
        </Footer>
      </Box>
    </Grommet>
  );
}

export default App;
